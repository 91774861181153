export const COOKIE_SESSION_LIFE_IN_MINUTE = 30;
export const STORAGE_SEON_SESSION_ID = "seonSessionId";

export const NOTIFY_COOKIE_NAME = "mail_nh";

export const COOKIE_IS_WEBP_COMPATIBLE = "__webp_is";

export const ONE_TIME_DISC_MAIL_COOKIE_KEY = "ontime_dis_mail";
export const MAIL_CONFIRMATION_COOKIE_KEY = "mail_confirm";

export const CO_REG_COOKIE_KEY = "co_reg";

export const ONE_TIME_DISC_COOKIE_KEY = "ontime_dis";

export const WEB_PUSH_SUBSCRIBE_DISC_COOKIE_KEY = "wsub_dis";

export const EVENT_DISC_COOKIE_KEY = "valentine_dis";

export const SENT_RANDOM_MSGS = "sent_random_msgs";

export const BILLING_TYPE_COOKIE_NAME = "__am_b_type";

export const REGISTRATION_COOKIE_NAME = "lad_co_r";

export const REGISTRATION_COOKIE_QUERY_NAME = "lad_q_r";

export const TEST_USER_COOKIE_KEY = "is_test_user";

export const COOKIE_SIMPLE_USER_DATA = "__am_user_sd";

export const EVENT_CONFIRM_CHAT = "confirm_chat";

export const CHAT_FIRST_MESSAGE_COOKIE = "__gtm-chat";

export const BIRTHDAY_ANIMATION_COOKIE_KEY = "birthday_animation";

export const CUPIDS_TOOLTIP_COOKIE_KEY = "cupids_tooltip";

export const GIFT_TOOLTIP_COOKIE_KEY = "gift_tooltip";

export const LAST_ACTIVE_CHAT_COOKIE_KEY = "last_active_chat";

export const HALLOWEEN_MODAL_OPENED_COOKIE_KEY = "halloween_modal_opened";
export const HALLOWEEN_NOTIFICATION_CLOSED_COOKIE_KEY =
  "halloween_notification_closed";

export const BLACK_FRIDAY_NOTIFICATION_CLOSED_COOKIE_KEY =
  "black_friday_notification_closed";
export const BLACK_FRIDAY_MODAL_OPENED_COOKIE_KEY = "black_friday_modal_opened";
export const HAPPY_BIRTHDAY_MODAL_OPENED_COOKIE_KEY =
  "happy_birthday_modal_opened";
export const CONTEST_COOKIE_KEY = "contest_page_opened";
export const USER_AVATAR_SWIPED = "user_avatar_swiped";
export const ALL_FEATURE_MODAL_OPENED_COOKIE_KEY = "all_features_modal_opened";
export const VIRTUAL_GIFTS_HIDDEN_COOKIE_KEY = "virtual_gifts_hidden";
export const US_LOCATION_USER_COOKIE_KEY = "us_user_location";
